import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import toast from 'react-hot-toast'
import api, { API_URL } from '../../api'
import axios from 'axios'

const LoginForm = ({ refresh }) => {
    const [isLoading, setIsLoading] = useState()
    const [userEmail, setUserEmail] = useState("")
    const [userPassword, setUserPassword] = useState("")

    const handleLogin = async () => {
        if (!userEmail || !userPassword) {
            toast.error("All fields are required")
            console.log("All fields are required")
            return
        }
        setIsLoading(true)
        const loginCredentials = {
            "username": userEmail,
            "password": userPassword
        }
        try {
            const response = await axios.post(`${API_URL}/accounts/token/`, loginCredentials)
            console.log(response)
            if (response.status === 200) {
                localStorage.setItem('access_token', response.data.access);
                const userData = await api.get(`${API_URL}/accounts/user/`)
                if (userData.status === 200) {
                    localStorage.setItem("user", JSON.stringify(userData.data.user))
                    localStorage.setItem("permissions", JSON.stringify(userData.data.permissions))
                    setIsLoading(false)
                    if (refresh) {
                        window.location.reload()
                    } else {
                        window.location.href = '/'
                    }
                }

            }
        } catch (error) {
            console.log(error)
            setIsLoading(false)
        }
    }
    return (
        <>
            <div className='login-form form'>
                <form action="">
                    <input onChange={(e) => setUserEmail(e.target.value)} type="text" name="userEmail" id="userEmail" placeholder='Username or email' />
                    <input onChange={(e) => setUserPassword(e.target.value)} type="password" name="userPassword" id="userPassword" placeholder='Password' />
                </form>
                <button className='primary-button full-button' onClick={handleLogin} type="button">
                    {
                        isLoading ? 'Loading...' : 'Login'
                    }
                </button>
            </div>
        </>
    )
}

export default LoginForm
