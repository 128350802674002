import React, { useEffect, useState } from 'react'
import { getNamesInitials } from '../services/utils'
import { Link } from 'react-router-dom'
import '../assets/css/header/header.css'
import { ArrowDown01Icon, CalendarAdd01Icon, DocumentAttachmentIcon, Home01Icon } from 'hugeicons-react'

const Header = ({ userData, userPermissions }) => {

    return (
        <div className='page-header'>
            <div className="container">
                <Link to={'/'} className='branding'>
                    <img className='logo' src="/logo192.png" alt="" />
                    <h4>CHHA Admin</h4>
                </Link>
                <div className="menu-items">
                    <Link className='item' to={'/'}> <Home01Icon size={22} /> Dashboard</Link >
                    <Link className='item' to={'/programs/'}> <DocumentAttachmentIcon size={22} />Programs</Link>
                    <Link className='item' to={'/events/'}><CalendarAdd01Icon size={22} /> Events</Link>
                </div>

                <div className="profile">
                    <select name="" id="" className='country'>
                        <option value="">Canada</option>
                        <option value="">Africa</option>
                    </select>
                    <div className="profile-pic">
                        <p>{getNamesInitials(`${userData.first_name} ${userData.last_name}`)}</p>
                    </div>
                    <ArrowDown01Icon />
                </div>
            </div>
        </div>
    )
}

export default Header
