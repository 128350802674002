import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import DashboardContainer from '../../../components/dashboardContainer'
import api from '../../../api'

const EventsPageContent = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [events, setEvents] = useState([])

    useEffect(() => {
        const getEvents = async () => {
            try {
                const response = await api.get('/events/')
                if (response.status === 200) {
                    setEvents(response.data.events)
                    console.log(response.data.events)
                    setIsLoading(false)
                }
            } catch (error) {
                console.error(error)
                setIsLoading(false)
            }
        }
        getEvents()
    }, [])
    return (
        <>
            <Helmet>
                <title>Events -CHHA Admin</title>
            </Helmet>
            <div className='events-page'>
                <h1>Events {events.length}</h1>
                <p>This is the Events page.</p>

                {
                    events.map((event, index) => (
                        <div key={index}>
                            <h2>{event.name}</h2>
                            <p>{event.description}</p>
                            <p>Date: {event.start_date}</p>
                        </div>
                    ))
                }
            </div>
        </>
    )
}

const EventsPage = () => {
    return (
        <DashboardContainer content={<EventsPageContent />} />
    )
}
export default EventsPage
