import axios from "axios";
const environment = process.env.REACT_APP_ENV;
export const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
    baseURL: API_URL
});

api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('access_token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

api.interceptors.response.use(
    (response) => {
        return response;
    },
    (error) => {
        if (error.response && (error.response.status === 403 || error.response.status === 401)) {
            localStorage.removeItem("access_token");
            window.location.reload();
        }
        return Promise.reject(error);
    }
);

export default api;
