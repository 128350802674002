import React from "react";
import Toaster from "react-hot-toast";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import DashboardPage from "./pages/dashboard/dashboardPage";
import ProtectedRoute from "./services/protectedRoute";
import ProgramsPage from "./pages/dashboard/programsPage";
import EventsPage from "./pages/dashboard/events/eventsPage";
import EventRegistrationsPage from "./pages/dashboard/events/eventRegistrationsPage";
import './assets/css/main/main.css';
function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<ProtectedRoute><DashboardPage /></ProtectedRoute>} />
          <Route path="/programs/" element={<ProtectedRoute><ProgramsPage /></ProtectedRoute>} />
          <Route path="/events/" element={<ProtectedRoute><EventsPage /></ProtectedRoute>} />
          <Route path="/events/:eventSlug/registrations/" element={<ProtectedRoute><EventRegistrationsPage /></ProtectedRoute>} />
        </Routes>
      </Router>
      {/* <Toaster
        position="bottom-center"
        reverseOrder={false}
      /> */}
    </div>
  );
}

export default App;
