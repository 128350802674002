import React from 'react'
import { Helmet } from 'react-helmet'
import LoginForm from '../../components/forms/loginForm'
import '../../assets/css/authPages/authPages.css'

const SessionExpired = () => {
    return (
        <>
            <Helmet><title>CHHA - Session Expired</title></Helmet>
            <div className='session-expired auth-page'>
                <div className="form">
                    <div className="branding">
                        <img className='logo' src="/logo.png" alt="" />
                    </div>
                    <h1 className='title'>Session expired</h1>
                    <p>Please log in again to access the dashboard.</p>
                    <LoginForm />
                </div>
            </div>
        </>
    )
}

export default SessionExpired
