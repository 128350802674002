// slice first and last name and get initials

export const getNamesInitials = (fullName) => {

    if (!fullName) return '';
    const names = fullName.split(' ');
    const firstInitial = names[0][0].toUpperCase();
    const lastInitial = names[names.length - 1][0].toUpperCase();

    return `${firstInitial}${lastInitial}`;
}

// take a date like this 2024-08-26T18:33:46.823483Z and convert it to to something like this format: 2015 - 08 - 03:00 am
export const convertDateToFormat = (dateString) => {
    if (!dateString) return '';

    const date = new Date(dateString);

    // Check if the date is valid
    if (isNaN(date.getTime())) return 'Invalid Date';

    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Determine AM/PM
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');

    return `${year}-${month}-${day} ${formattedHours}:${minutes} ${period}`;
};

// slice text
export const slicedText = (text, maxLength) => {
    if (!text || text.length <= maxLength) return text;

    return `${text.slice(0, maxLength)}...`;
};