import React, { useEffect, useState } from 'react'
import DashboardContainer from '../../../components/dashboardContainer'
import { useParams } from 'react-router-dom'
import api from '../../../api'
import { convertDateToFormat } from '../../../services/utils'
import '../../../assets/css/eventRegistrations/eventRegistrations.css'
const EventRegistrationsPageContent = () => {
    const { eventSlug } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [eventRegistrations, setEventRegistrations] = useState([])

    useEffect(() => {
        const getEventRegistrations = async () => {
            try {
                const response = await api.get(`/events/${eventSlug}/registrations/`)
                if (response.status === 200) {
                    console.log(response.data.registrations)
                    setEventRegistrations(response.data.registrations)
                    setIsLoading(false)
                }
            } catch (error) {
                console.log(error)
            }
        }
        getEventRegistrations()
    }, [])
    return (
        <div className='events-registration-page'>
            <div className="container">

                <div className="event-and-registrations">
                    <div className="event">
                        <h2>Event details</h2>
                        <small>Feb 14, 2024</small>
                        <h4>Education of underprivileged children</h4>
                        <p>First time to conduct meetings in English - Most of the students were shy but left the meeting place positively and ready to practice English at</p>
                        <img src="/senior-citizens-1429012_1920-e1614721003632.png" alt="" />
                        <button className="primary-button">View more details</button>
                    </div>
                    <div className="registrations">
                        <h3>Event registrations <span className='count'>{eventRegistrations.length}</span> </h3>
                        <table>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone number</th>
                                    <th>Date</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    eventRegistrations.map((reg, index) => (
                                        <tr>
                                            <td>{reg.name}</td>
                                            <td>{reg.email}</td>
                                            <td>{reg.phone_number}</td>
                                            <td>{convertDateToFormat(reg.created_at)}</td>
                                        </tr>
                                    ))
                                }
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
        </div>
    )
}

const EventRegistrationsPage = () => {
    return (
        <DashboardContainer content={<EventRegistrationsPageContent />} />
    )
}

export default EventRegistrationsPage
