import React, { useEffect, useState } from 'react'
import Header from './header'
import SessionExpired from '../pages/auth/sessionExpired'

const DashboardContainer = ({ content }) => {
    const [userData, setUserData] = useState({})
    const [userPermissions, setUserPermissions] = useState([])
    const [canAccessDashboard, setCanAccessDashboard] = useState(false)

    useEffect(() => {
        const user = JSON.parse(localStorage.getItem('user'))
        const permissions = JSON.parse(localStorage.getItem('permissions'))
        console.log('user ', user)
        console.log('permissions ', permissions)
        if (user && permissions) {
            setCanAccessDashboard(true)
            setUserData(user)
            setUserPermissions(permissions)
        }
    }, [])
    return canAccessDashboard ? (
        <div className='dashboard-container'>
            <Header userData={userData} userPermissions={userPermissions} />
            {content}
        </div>
    )
        : <SessionExpired />
}

export default DashboardContainer
