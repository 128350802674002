import React from 'react'
import { Helmet } from 'react-helmet'
import DashboardContainer from '../../components/dashboardContainer'

const ProgramsPageContent = () => {
    return (
        <>
            <Helmet>
                <title>Programs - CHHA Admin</title>
            </Helmet>
            <div className='programs-page'>
                <h1>Programs page</h1>
                <p>This is the Programs page.</p>
            </div>
        </>
    )
}

const ProgramsPage = () => {
    return (
        <DashboardContainer content={<ProgramsPageContent />} />
    )
}

export default ProgramsPage
