import React, { useEffect, useState } from 'react'
import Header from '../../components/header'
import DashboardContainer from '../../components/dashboardContainer'
import api from '../../api'
import { convertDateToFormat, slicedText } from '../../services/utils'
import { Link } from 'react-router-dom'
import '../../assets/css/dashboard/dashboard.css'

const DashboardPageContent = ({ }) => {
    // get current time and format it like 17 Jun 2024 10:00 am
    const currentTime = new Date().toLocaleString('en-US', {
        weekday: 'long',
        year: 'numeric',
        month: 'long',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
    });
    // get necessary-data
    const [dashboardData, setDashboardData] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    useEffect(() => {
        const getDashboardData = async () => {
            try {
                const response = await api.get('/dashboard/')
                console.log(response)
                if (response.status === 200) {
                    setDashboardData(response.data)
                    setIsLoading(false)
                }
            } catch (error) {
                console.error(error)
                setIsLoading(false)
            }
        }

        getDashboardData()
    }, [])
    return isLoading ? 'Loading' : (
        <div className='dashboard-page'>
            <div className="container">
                <div className="welcome-text">
                    <h1>Hi { }, Welcome back</h1>
                    <p>Welcome to your dashboard!</p>
                    <small>{currentTime}</small>
                </div>

                <div className="events-programs">
                    <div className="events card">
                        <div className="card-header">
                            <h3>Upcoming events</h3>
                            <Link className='card-link'>View all events</Link>
                        </div>
                        <div className="events-list">
                            {dashboardData.events && dashboardData.events.map((event, index) => (
                                <Link to={`/events/${event.slug}/registrations/`} className='event' key={index}>
                                    <div className="img"></div>
                                    <div className="text">
                                        <small>Date: {convertDateToFormat(event.start_date)}</small>
                                        <h2>{slicedText(event.name, 30)}</h2>
                                        <p>{slicedText(event.description, 50)}</p>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>

                    <div className="programs card">
                        <h1>Events</h1>
                        {dashboardData.programs && dashboardData.programs.slice(0, 2).map((event, index) => (
                            <div key={index}>
                                <h2>{event.name}</h2>
                                <p>{event.description}</p>
                                <p>Date: {convertDateToFormat(event.start_date)}</p>
                            </div>
                        ))}
                    </div>
                </div>

                <div className="events-registrations">
                    <div className="card-header">
                        <h1>Events registrations</h1>
                        <Link className='card-link'>View all</Link>
                    </div>
                    <div className="recent-registration">
                        <table>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>Phone number</th>
                                    <th>Event</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    dashboardData.event_registrations && dashboardData.event_registrations.map((registration, index) => (
                                        <tr key={index}>
                                            <td>{convertDateToFormat(registration.created_at)}</td>
                                            <td>{registration.name}</td>
                                            <td>{registration.email}</td>
                                            <td>{registration.phone_number}</td>
                                            <td><Link to={`/events/${registration.event_slug || 'food-drive'}/registrations/`}>{registration.event}</Link></td>
                                        </tr>
                                    ))
                                }
                            </tbody>

                        </table>
                    </div>

                </div>
            </div>
        </div>
    )
}

const DashboardPage = () => {
    return (
        <DashboardContainer content={<DashboardPageContent />} />
    )
}

export default DashboardPage
